app.filter('filterArrayObj', function() {
  return function(propertyName, propertyValue, collection) {
        var i=0, len=collection.length;
        for (; i<len; i++) {
            if (collection[i][propertyName].toLowerCase() == propertyValue.toLowerCase()) {
                return collection[i];
            }
        }
        return null;
    }
});


app.filter('multiJobFilter', function() {
  return function(vacancies, filterData) {
        if(angular.isDefined(filterData) && filterData!='' && vacancies!=null) {
        var results = [];
        var i;
        var keepdoing = true;
        angular.forEach(filterData, function(obj, key){

            for(i = 0; i < vacancies.length; i++){
                    if(key == 'employer'){
                        var ids = obj.id;
                        if(ids != undefined && ids.length){
                            if(ids.indexOf(vacancies[i][key].id) !== -1){
                                if(results.indexOf(vacancies[i]) == -1){
                                    results.push(vacancies[i]);
                                }
                            }
                        }else{
                            if(results.indexOf(vacancies[i]) == -1) {
                                results.push(vacancies[i]);
                            }
                        }
                    }else{
                        if(!vacancies[i][key].length && (filterData[key].id==undefined || !filterData[key].id.length)){
                            results.push(vacancies[i]);
                        }else{
                            angular.forEach(vacancies[i][key], function(obj1, key1){
                                var ids = obj.id;
                                if(ids != undefined && ids.length){
                                if(ids.indexOf(obj1.id) !== -1){
                                    if (results.indexOf(vacancies[i]) == -1) {
                                        results.push(vacancies[i]);
                                    }
                                }
                            }else{
                                if(results.indexOf(vacancies[i]) == -1) {
                                    results.push(vacancies[i]);
                                }
                            }
                            });
                        }
                    }
                }
        });
        return results;
      }else {
        return vacancies;
    }
  };
});

app.filter('multiProfileFilter', function() {
    return function(vacancies, filterData) {
        
        if(angular.isDefined(filterData) && filterData!='' && vacancies!=null) {
           
            var results = [];
            var i;
            var keepdoing = true;
            angular.forEach(filterData, function(obj, key){
                for(i = 0; i < vacancies.length; i++){
                   // console.log(vacancies[i][key]);
                    if(!vacancies[i][key].length && (filterData[key].id==undefined || !filterData[key].id.length)){
                        results.push(vacancies[i]);
                    }else{ 
                      //console.log("hello");
                        angular.forEach(vacancies[i][key], function(obj1, key1){
                            var ids = obj.id;
                           // console.log(ids);
                            if(ids != undefined && ids.length){  
                               // console.log(ids.indexOf(obj1.id+key));
                                if(ids.indexOf(obj1.id) !== -1){
                                    if (results.indexOf(vacancies[i]) == -1) {
                                      results.push(vacancies[i]);
                                     // console.log(results);           
                                    }
                                }

                            }else{  
                                if(results.indexOf(vacancies[i]) == -1) {
                                    results.push(vacancies[i]);
                                }
                            }
                            
                        });
                    }
                    ///}
                }
            });
            //console.log(results);
            return results;
        }else {
            return vacancies;
        }
    };
});


app.filter('firstLetterFilter', [function () {
  return function (list) {
    //console.log(list);
    var firstLetters = [];
    angular.forEach(list, function (item) {
      var firstLetter = item.option.charAt(0).toUpperCase();
      if (firstLetters.indexOf(firstLetter) === -1) {
        firstLetters.push(firstLetter);
      }
    });
    return firstLetters;
  }
  }]);


app.filter('renderHtml', function($sce) { return $sce.trustAsHtml; });


app.filter('isUndefined', function () {
      return function (input) {
        return angular.isUndefined(input);
      }
    })
    .filter('isDefined', function() {
      return function (input) {
        return angular.isDefined(input);
      }
    })
    .filter('isFunction', function() {
      return function (input) {
        return angular.isFunction(input);
      }
    })
    .filter('isString', function() {
      return function (input) {
        return angular.isString(input)
      }
    })
    .filter('isNumber', function() {
      return function (input) {
        return angular.isNumber(input);
      }
    })
    .filter('isArray', function() {
      return function (input) {
        return angular.isArray(input);
      }
    })
    .filter('isObject', function() {
      return function (input) {
        return angular.isObject(input);
      }
    })
    .filter('isEqual', function() {
      return function (o1, o2) {
        return angular.equals(o1, o2);
      }
    })
.filter('truncate', function ($filter) {
    return function(input, length, suffix, preserve) {

      length = $filter('isUndefined')(length) ? input.length : length;
      preserve = preserve || false;
      suffix = suffix || '';

      if(!$filter('isString')(input) || (input.length <= length)) return input;

      return input.substring(0, (preserve)
        ? ((input.indexOf(' ', length) === -1) ? input.length : input.indexOf(' ', length))
        : length) + suffix;
    };
});

app.filter("ucwords", function () {
    return function (input){
        if(input) { //when input is defined the apply filter
           input = input.toLowerCase().replace(/\b[a-z]/g, function(letter) {
              return letter.toUpperCase();
           });
        }
        return input; 
    }    
})


app.filter('capitalize', function() {
  return function(input, scope) {
    if (input!=null)
    input = input.toLowerCase();
    return input.substring(0,1).toUpperCase()+input.substring(1);
  }
});
app.filter('secondsToDateTime', [function() {
    return function(seconds) {
        return new Date(1970, 0, 1).setSeconds(seconds);
    };
}]);
app.filter('unique', function() {
   return function(collection, keyname) {
      var output = [], 
          keys = [];

      angular.forEach(collection, function(item) {
          var key = item[keyname];
          if(keys.indexOf(key) === -1) {
              keys.push(key);
              output.push(item);
          }
      });

      return output;
   };
});